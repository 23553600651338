import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  forwardRef,
  useImperativeHandle
} from 'react'
import { Player } from '@lottiefiles/react-lottie-player'
import { useRouter } from 'next/router'
import styled from 'styled-components'
import ProfileCircle from '../DesignerProfile/ProfileCircle'
import { ReactComponent as CoinIcon } from '../../assets/coin.svg'
import { ReactComponent as ExpIcon } from '../../assets/exp.svg'
import { ReactComponent as WinBadgeIcon } from '../../assets/userEvent/winBadge.svg'
import { ReactComponent as DMIcon } from '../../assets/dm-logo.svg'
import newUserBadge from '../../assets/new-user-badge.png'
import { useIsMobile } from '../../hooks/useIsMobile'
import manyCoins from '../../assets/Coins.svg'

const RewardedContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 9px;
  margin-bottom: 9px;
`

const RewardedInnerContainer = styled.div`
  display: flex;
  width: 60%;
  align-self: center;
  align-items: center;
`

const RewardTitle = styled.div`
  color: #ff5e6d;
  font-weight: 700;
  font-size: 14px;
  white-space: nowrap;
`

const RewardExpTitle = styled.div`
  color: #ff5e6d;
  font-weight: bold;
  font-size: 14px;
  white-space: nowrap;
  margin: 0 auto;
`

const Coin = styled(CoinIcon)`
  width: 46px;
  height: 46px;
  margin-right: 10px;
`

const Badge = styled.div`
  width: 46px;
  height: 46px;
  margin-right: 10px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`

const Rewarded = ({ type, data, label }) => {
  const [badgeIcon, setBadgeIcon] = useState()

  useEffect(() => {
    if (!data) return
    if (data.badge && data.badge.imageFiles)
      setBadgeIcon(data.badge.imageFiles[data.badge.imageFiles.length - 1].url)
  }, [data])

  return (
    <RewardedContainer>
      <RewardedInnerContainer>
        {type === 'coin' && <Coin />}
        {type === 'badge' && (
          <Badge style={{ backgroundImage: `url(${badgeIcon})` }} />
        )}
        <RewardTitle>
          + {label}
          {type === 'coin' && ' Dcoins'}
        </RewardTitle>
      </RewardedInnerContainer>
    </RewardedContainer>
  )
}

//-----------------------------------------------------------------

const ProgressContainer = styled.div`
  position: relative;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
`

const ProgressInnerContainer = styled.div`
  display: flex;
  align-self: center;
  align-items: center;
`

const ProgressBadge = styled.div`
  width: 70px;
  height: 70px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`

export const BadgeProgress = ({ data }) => {
  const rLottie = useRef()

  const [anime, setAnime] = useState()
  const [badgeIcon, setBadgeIcon] = useState()

  useEffect(() => {
    if (!data) return

    if (data?.currentCFProgressUrl) setAnime(data?.currentCFProgressUrl)
    else setAnime()

    if (data?.badge && data?.badge?.imageFiles)
      setBadgeIcon(data.badge.imageFiles[data.badge.imageFiles.length - 1].url)

    rLottie?.current?.play()
  }, [data])

  return (
    <ProgressContainer>
      <ProgressInnerContainer>
        {anime && (
          <Player
            autoplay={true}
            loop={false}
            keepLastFrame={true}
            ref={rLottie}
            src={data.currentCFProgressUrl}
            style={{
              width: '200px'
            }}
          />
        )}
        {badgeIcon && (
          <ProgressBadge style={{ backgroundImage: `url(${badgeIcon})` }} />
        )}
      </ProgressInnerContainer>
    </ProgressContainer>
  )
}

//-----------------------------------------------------------------

const Container = styled.div`
  /*background-color: ${props => props.theme.post.side};*/
  background-color: rgba(255, 242, 243, 0.36);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 20px;
  flex: 0 0 240px;

  @media (min-width: 1200px) {
    flex: 0 0 410px;
  }

  @media (max-width: 1024px) {
    flex: auto;
    background: #fff;
    padding: 0;
  }
`

const InnerContainer = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;

  @media (max-width: 1024px) {
    margin-top: -90px;
    margin-bottom: 10px;
  }
`

const ProfileContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: center;
  align-self: safe center;
  align-items: center;
  margin-bottom: 20px;

  @media (max-width: 1024px) {
    transform: scale(0.6);
    margin: 0;
  }
`

const ProfileAvatarContainer = styled.div`
  position: relative;
  width: 180px;
`

const Title = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  color: ${props => props.theme.text.primary};
  text-align: center;

  @media (min-width: 1200px) {
    font-size: 24px;
  }
  margin-top: 20px;
  margin-bottom: 20px;

  @media (max-width: 1024px) {
    margin: 0;
    font-size: 18px;
    font-weight: 700;
  }
`

const Message = styled.div`
  font-size: 16px;
  color: ${props => props.theme.text.secondary};
  margin-bottom: 30px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  line-height: 24px;

  @media (max-width: 1024px) {
    font-size: 14px;
    color: #646464;
    margin: 0;
  }
`

const DMLogoContainer = styled.div`
  display: none;
  @media only screen and (min-width: 1024px) {
    display: block;
  }
`
const ClearButton = styled.button`
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  margin-left: 20px;
`

const DMLogo = styled(DMIcon)``

const PostedDesignDescription = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
  text-align: center;
  width: 100%;
  margin-top: 45px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const DescriptionLine = styled.div`
  display: flex;
  align-items: center;
  justify-contnet: center;
`

const IconWrapper = styled.div`
  margin-left: 5px;
  margin-right: 7px;
  width: 24px;
  height: 24px;

  &:last-child {
    margin-left: 10px;
  }
`

const EarningLine = styled.div`
  display: flex;
  align-items: center;
  justify-conenter: center;
  font-weight: 700;
  font-size: 18px;
  line-height: 19px;
  color: #ff5e6d;
  text-align: center;
  margin-top: 16px;
`

const MobileTutorialSummary = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;

  & span {
    color: #ff3939;
    font-weight: 700;
    font-size: 18px;
    line-height: 19px;
  }
`

const MobileTutorialSummaryText = styled.p`
  max-width: 270px;
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  text-align: center;
  color: #000000;
  margin: 0 auto;
  margin-top: 15px;

  & span {
    color: #ff3939;
  }
`

const PostProfile = forwardRef(
  ({ profile, startLevel, endLevel, coins, badge, exp }, ref) => {
    const router = useRouter()
    const isTutorial = router.query.tutorial === 'true'
    const rProfileCircle = useRef()
    const [picture, setPicture] = useState('')
    const isMobile = useIsMobile()

    const [sLevel, setSlevel] = useState()
    const [eLevel, setElevel] = useState()
    const [badgeStatus, setBadgeStatus] = useState()
    const [step, setStep] = useState(0)
    const [isLevelUp, setIsLevelUp] = useState(false)

    const reset = useCallback(d => {
      setIsLevelUp(false)
      setSlevel(0)
      setElevel(0)
      setPicture('')
    }, [])

    useEffect(() => {
      if (!badge) {
        setBadgeStatus()
        return
      }

      setBadgeStatus(badge.status)

      const p = badge.numPosts || 0
      const r = badge.badge.numPostsRequired || 0

      setStep(r - p)
    }, [badge])

    useEffect(() => {
      reset()
      if (!profile) return

      var slvl = 0
      var elvl = 0
      if (!startLevel && !endLevel) {
        slvl = profile.userLevel
        elvl = profile.userLevel
        setSlevel(slvl)
        setElevel(elvl)
      } else {
        slvl = startLevel
        elvl = endLevel

        setSlevel(startLevel)
        setElevel(endLevel)
      }

      if (!slvl) return
      const dif = Math.floor(elvl) - Math.floor(slvl)

      if (dif > 0) setIsLevelUp(true)
      else setIsLevelUp(false)
    }, [profile, startLevel, endLevel, reset])

    useEffect(() => {
      if (!profile) return
      setPicture(
        profile?.funAvatarImageUrl ||
          profile?.thumbProfileImageFile?.url ||
          profile?.pic ||
          'https://didr9pubr8qfh.cloudfront.net/mobile_other/profile_avatars/Profile5.png'
      )
    }, [profile])

    useImperativeHandle(ref, () => ({
      play: () => {
        rProfileCircle.current.play()
      }
    }))

    return (
      <Container>
        <InnerContainer>
          <ProfileContainer>
            <ProfileAvatarContainer>
              <ProfileCircle
                ref={rProfileCircle}
                picture={picture}
                startLevel={sLevel}
                endLevel={eLevel}
                autoStart
              />
              {profile?.funAccessoriesImageUrl && (
                <img
                  src={profile?.funAccessoriesImageUrl}
                  style={{
                    position: 'absolute',
                    left: '50%',
                    top: 'calc(50% - 14px)',
                    width: '225px',
                    height: '225px',
                    transform: 'translate(-50%, -50%)',
                    zIndex: 999
                  }}
                />
              )}
            </ProfileAvatarContainer>
          </ProfileContainer>
          <Title>{isTutorial ? 'Welcome on Board!' : 'Design Posted!'}</Title>
          {!!exp && !isTutorial && <RewardExpTitle>+{exp} exp</RewardExpTitle>}
          {router.query.userEvent && (
            <PostedDesignDescription>
              <DescriptionLine>
                Winners will earn{' '}
                <IconWrapper>
                  <CoinIcon width={24} height={24} />
                </IconWrapper>{' '}
                2000{' '}
                <IconWrapper>
                  <WinBadgeIcon width={24} height={24} />
                </IconWrapper>{' '}
                1
              </DescriptionLine>
              <DescriptionLine>
                Results will be ready one day after the event end
              </DescriptionLine>
              <EarningLine>
                <CoinIcon width={24} height={24} style={{ marginRight: 3 }} />
                <span>+ 100 Dcoins</span>
                <ExpIcon
                  width={28}
                  height={28}
                  style={{ marginLeft: 10, marginRight: 1 }}
                />
                <span>+ {exp} EXP</span>
              </EarningLine>
            </PostedDesignDescription>
          )}
          {isTutorial && !isMobile && (
            <>
              <Rewarded
                type="badge"
                data={{ badge: { imageFiles: [{ url: newUserBadge }] } }}
                label="New User Badge"
              />
              <Rewarded type="coin" label={100} />
            </>
          )}
          {isTutorial && isMobile && (
            <>
              <MobileTutorialSummaryText>
                You’ve earned a badge shown on your profile and{' '}
                <span>+100</span> Dcoins
              </MobileTutorialSummaryText>
              <MobileTutorialSummary>
                <img src={manyCoins} alt="" width={60} height={48} />
                <span>+</span>
                <img src={newUserBadge} alt="" width={41} height={41} />
              </MobileTutorialSummary>
            </>
          )}
          {coins > 0 && <Rewarded type="coin" label={coins} />}
          {badgeStatus && badgeStatus === 'active' && (
            <Rewarded
              type="badge"
              data={badge}
              label={badge.badge.title + ' Badge'}
            />
          )}
          {badgeStatus && badgeStatus === 'inProgress' && (
            <BadgeProgress data={badge} />
          )}
        </InnerContainer>

        <Message>
          Your design is published in Explore now!
          <br />
          {isLevelUp === true &&
            'Leveled up to L' + Math.floor(endLevel || 0) + '! '}
          {coins > 0 && 'Enjoy +' + coins + ' Dcoins reward. '}
          {badgeStatus &&
            badgeStatus === 'inProgress' &&
            'You need ' + step + ' more DesignerDesigns to earn the '}
          {badgeStatus && badgeStatus === 'inProgress' && (
            <strong>{badge.badge.title}</strong>
          )}
          {badgeStatus && badgeStatus === 'inProgress' && ' badge. '}
          {badgeStatus && badgeStatus === 'active' && 'You have earned the '}
          {badgeStatus && badgeStatus === 'active' && (
            <strong>{badge.badge.title}</strong>
          )}
          {badgeStatus && badgeStatus === 'active' && ' badge! '}
        </Message>
      </Container>
    )
  }
)

export default PostProfile
