import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  forwardRef,
  useImperativeHandle
} from 'react'
import styled from 'styled-components'
import { CircularProgressbar } from 'react-circular-progressbar'

import animationData from './assets/level_glow_bg.json'
import { Player } from '@lottiefiles/react-lottie-player'
import GradientSVG from './GradientSVG'

const Container = styled.div`
  position: relative;
  padding: 0;
  margin: 0;
  pointer-events: none;
`

const CenterContainer = styled.div`
  position: relative;
`

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 98px;
  height: 98px;
  background-size: cover;
  border-radius: 50%;
  background-color: white;
  border: 0px !important;
  box-shadow: none;
  user-select: none;
  z-index: 2;
`

const ExpContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 98px;
  height: 98px;
  z-index: 3;
`

const PictureContainer = styled.div`
  position: absolute;
  top: 8px;
  left: 8px;
  padding: 0;
  margin: 0;
  border: none;
  outline: 0;
  z-index: 4;
`

const Placeholder = styled.div`
  width: 98px;
  height: 98px;
`

const Picture = styled.div`
  width: 82px;
  height: 82px;
  background-size: cover;
  border-radius: 50%;
  background-color: #f5f5f5;
  border: 0px !important;
  box-shadow: none;
  user-select: none;
`

const LevelTag = styled.div`
  margin: 0;
  position: absolute;
  z-index: 2;
  bottom: -4px;
  right: 0px;
  border-radius: 15px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 18px;
  text-align: center;
  font-size: 9px;
  font-weight: 500;
  user-select: none;
  box-shadow: ${props =>
    props.active === false
      ? '1px 1px 6px rgba(245,245,245,.3)'
      : '1px 1px 6px rgba(255,94,109,.3)'};
  color: ${props => (props.active === false ? '#f5f5f5' : 'white')};
  background-color: ${props =>
    props.active === false ? '#f5f5f5' : '#ff5e6d'};
`

const ProfileCircle = forwardRef(
  ({ picture, startLevel, endLevel, autoStart }, ref) => {
    const rGlow = useRef()
    const rTimer = useRef()
    const rCurRev = useRef(0)
    const rRevolutions = useRef([])
    const [exp, setExp] = useState(0)
    const [animateExp, setAnimateExp] = useState(false)
    const [gradientId] = useState('grad-id')

    const ExpStyle = {
      root: {},
      path: {
        stroke: `url(#${gradientId})`,
        strokeLinecap: 'round',
        transition:
          animateExp === false
            ? 'stroke-dashoffset 0s ease 0s'
            : 'stroke-dashoffset 2.0s ease 0s',
        transformOrigin: 'center center',
        transform: 'rotate(0.405turn)'
      },
      trail: {
        stroke: '#F1F1F1',
        strokeLinecap: 'round',
        transformOrigin: 'center center',
        transform: 'rotate(0.405turn)'
      }
    }

    const startRevolutions = useCallback(() => {
      clearTimeout(rTimer.current)

      if (rRevolutions.current.length === 0) return

      setAnimateExp(false)
      setExp(rRevolutions.current[rCurRev.current][0])

      rTimer.current = setTimeout(() => {
        setAnimateExp(true)
        setExp(rRevolutions.current[rCurRev.current][1])
        rTimer.current = setTimeout(() => {
          if (rRevolutions.current[rCurRev.current][1] === 100) {
            setAnimateExp(false)
            setExp(0)
          }
          rGlow.current.stop()
          rGlow.current.play()
          rCurRev.current++
          if (!rRevolutions.current[rCurRev.current]) return
          else startRevolutions()
        }, 2000)
      }, 50)
    }, [])

    useImperativeHandle(ref, () => ({
      play: () => {
        startRevolutions()
      }
    }))

    useEffect(() => {
      return () => clearTimeout(rTimer.current)
    }, [])

    useEffect(() => {
      if (!startLevel) return
      rCurRev.current = 0
      const lvl = startLevel.toString().split('.')

      const slvl = startLevel.toFixed(2).split('.')
      const elvl = endLevel.toFixed(2).split('.')

      const sl = Math.trunc(startLevel)
      const el = Math.trunc(endLevel)

      const sexp = parseInt(slvl[1])
      const eexp = parseInt(elvl[1])

      if (sl === el) {
        rRevolutions.current = [[sexp, eexp, el]]
      } else {
        rRevolutions.current = []
        rRevolutions.current.push([sexp, 100, sl + 1])
        for (var i = sl + 1; i < el; i++) {
          rRevolutions.current.push([0, 100, i + 1])
        }
        rRevolutions.current.push([0, eexp, el])
      }

      startRevolutions()
    }, [autoStart, startLevel, endLevel, startRevolutions])

    /*--------------------------
  // THERES A PROBLEM WITH GRADIENT WHEN BROWSER RESIZE
  ---------------------------*/
    return (
      <>
        <Container>
          <CenterContainer>
            <Player
              ref={rGlow}
              src={animationData}
              style={{
                height: '200px',
                width: '200px',
                position: 'absolute',
                top: '-50px',
                left: '-50px',
                zIndex: '1'
              }}
            />
            <Background />
            <ExpContainer>
              <CircularProgressbar
                styles={ExpStyle}
                strokeWidth={6}
                circleRatio={0.945}
                value={exp}
              />
            </ExpContainer>
            <PictureContainer>
              <Picture style={{ backgroundImage: `url(${picture})` }} />
              <LevelTag active={!endLevel ? false : true}>
                L {endLevel ? Math.floor(endLevel) : 0}
              </LevelTag>
            </PictureContainer>
            <Placeholder />
          </CenterContainer>
        </Container>
        <GradientSVG
          startColor="#FF8171"
          endColor="#FF6A8E"
          idCSS={gradientId}
          rotation="90"
        />
      </>
    )
  }
)

export default ProfileCircle
